<template>
  <NuxtI18nLink
    to="/sport/live"
    class="live-now-banner"
    :class="classes"
    data-t="live-now-banner"
  >
    <div class="image-container" />
    <div class="description">
      <p class="title">{{ t('mainPage.liveNow') }}</p>
      <div class="live-now">
        <StLiveIndicator :size="16" color="orange" />
        <span class="count" data-t="live-counter">{{ counter }}</span>
      </div>
    </div>
  </NuxtI18nLink>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    counter: number
    isMini?: boolean
    size?: 's' | 'm'
  }>(),
  {
    size: 's',
  },
)

const { t } = useI18n()
const classes = computed(() => [{ mini: props.isMini }, props.size])
</script>

<style scoped>
.image-container {
  width: 100%;
  height: 74px;

  background: url('./assets/bannerBg.png'), #fe5e01;
  background-size: cover;
  border-radius: var(--border-radius-150);
}

.description {
  display: flex;
  gap: var(--spacing-050);
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  overflow: hidden;

  margin: 0;

  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
  text-overflow: ellipsis;
  white-space: nowrap;

  transition: opacity 0.2s;
}

.live-now {
  display: flex;
  align-items: center;

  margin-top: 3px; /* stylelint-disable-line */
  padding-right: var(--spacing-050);

  font: var(--desktop-text-xs-medium);
  color: var(--text-primary);

  transition: opacity 0.2s;
}

.live-now-banner {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-075);

  width: 112px;

  text-decoration: none;

  &.mini {
    .title {
      max-width: 54px;
      opacity: 0.01;
    }

    .live-now {
      opacity: 0.01;
    }
  }

  &.m {
    width: 120px;

    .image-container {
      height: 80px;
      transition: transform 0.2s;
    }

    &:hover {
      .image-container {
        transform: translateY(-6px);
      }
    }
  }
}
</style>
